
a {
  text-decoration: none;
  color: inherit;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffffff; /* Fondo blanco para la página */
}

.Cabecera {
  background-color: green; /* Rojo para representar la bandera de Chile */
  color: white; /* Color del texto en el encabezado */
  padding: 25px; /* Espaciado interno del encabezado */
  width: 100%; /* Ancho al 100% del contenedor principal */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Sombra ligera para el encabezado */
  border: 1px solid #000000; /* Borde negro para el encabezado */
  display: flex; /* Activar el modelo de caja flexible */
  justify-content: center; /* Centrar horizontalmente los elementos hijos */
  align-items: center; /* Centrar verticalmente los elementos hijos */
}

.Cabecera button {
  margin: 0 px; /* Añadir margen a los botones para separarlos */
}

.SubCabecera {
  background-color: black; 
  color: black; 
  padding: 50px;
  width: 100%; 
  border: 1px solid #000000; 
  position: relative;
}

.SubCabeceraInicio {
  background-color: #0033a0; /* Azul para representar la bandera de Chile */
  color: black; /* Color del texto en el contenedor de los subs */
  padding: 15px; /* Espaciado interno del contenedor de los subs */
  width: 100%; /* Ancho al 100% del contenedor principal */
  border: 1px solid #000000; /* Borde negro para el contenedor de los subs */
  position: relative;
}

.logoAndTitle {
  display: flex;
  align-items: center; /* Alinear verticalmente los elementos */
  margin-top: -110px;
}


.grisCuadro {
  background-color: #f0f0f0; /* Color gris */
  padding: 30px; /* Espacio interior del cuadro */
  border-radius: 20px; /* Bordes redondeados */
  margin: 25px; /* Espacio exterior del cuadro */
}

.logo {
  border: none;
  padding: 0;
  background: none;
  width: 10%;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  padding: 5px;
  border: 1px solid #ddd;
}

th {
  text-align: center;
}

@media (max-width: 768px) {
  .logo {
    width: 50%;
    margin: 0 auto; /* Centra el logo horizontalmente */
  }
  .logoAndTitle {
    display: flex;
    align-items: center;
    margin-top: 10px; /* Modifica este valor para subir el logo */
  }
  
  
}
